<template>
<section class="dynamic-manager">
    <el-form :inline="true" class="search-wrapper" :mode="searchMode">
        <el-form-item label="时间">
            <el-date-picker
                v-model="searchMode.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="搜索">
            <el-input v-model="searchMode.search" class="search-input" placeholder="动态内容"></el-input>
        </el-form-item>
        <el-form-item>
            <el-button class="search-btn" type="primary" @click="searchHandler">搜索</el-button>
            <el-button class="search-btn"  @click="resetHandler">重置</el-button>
        </el-form-item>
    </el-form>
    <div class="dynamic-header flex space-between">
        <h2 class="title">动态列表</h2>
        <div>
            <el-button type="primary" @click="delsHandler">批量删除</el-button>
            <router-link class="add-good" :to="{name:'releaseDynamics'}"> 
                <span class="el-icon el-icon-plus"></span>
                <span>发布动态</span>
            </router-link>
        </div>
    </div>
    
    <!-- table -->
    <el-table
        class="table-wrapper"
        :data="list"
        :header-cell-style="{color: '#000',fontWeigth: 'bold',background: '#FAFAFA',textAlign:'center'}"
        @selection-change="selectionChangeHandler"
        style="width:100%; min-height: 500px;">
        <el-table-column
            type="selection"
            width="55">
        </el-table-column>
        <el-table-column
            label="标题"
            width="200">
             <template slot-scope="scope">
                <p style="white-space:pre-line">{{scope.row.text | ellipsis}}</p>
            </template>
        </el-table-column>
        <el-table-column
            prop="shareCount"
            align="center"
            label="点赞数">
        </el-table-column>
        <el-table-column
            prop="createTimeS"
            align="center"
            sortable
            label="发布时间">
            <template slot-scope="scope">
                {{scope.row.createTimeS*1000 | formatDate}}
            </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="操作">
            <template slot-scope="scope">
                <span class="table-btn" @click="getDetailHandler(scope.row._id,scope.$index)">查看</span>
                <span class="table-btn" @click="editHandler(scope.row._id)">编辑</span>
                <span class="table-btn" @click="delHandler(scope.row._id)">删除</span>
            </template>
        </el-table-column>
    </el-table>

    <el-pagination
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10,15]"
        :page-size="100"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>

    <!-- 动态详情 -->
    <el-dialog title="动态" :visible.sync="dialogDyVisible">
        <div class="dialog-dynamic">
            <p class="dynamic-t" style="white-space:pre-line">{{detail.text}}</p>
            <div class="dynamic-img">
                <el-image :src="item" alt="" class="img" v-for="(item,i) in detail.mediaUrl" :key="i"></el-image>
            </div>
        </div>
    </el-dialog>
</section>
</template>

<script>
let detailCache = [];
let delIds = [];
export default {
    data(){
        return {
            searchMode:{
                time:[],
                search:''
            },
            list:[],
            currentPage:1,
            rows:10,
            total:0,
            dialogDyVisible: false, 
            detail:{}
        }
    },
    created(){
        detailCache = [];
        this.getListHandler();
    },
    methods:{
        async getListHandler(){
            const res = await this.$https.cloud({
                                action:'activity',
                                type:'list',
                                formData:{
                                    startTime: this.searchMode.time[0],
                                    endTime: this.searchMode.time[1],
                                    search: this.searchMode.search,
                                    page: this.currentPage,
                                    rows: this.rows
                                }
                            });
            if(res.code!=0) return;
            this.list = res.data.list;
            this.total = res.data.total;
        },

        //  获取详情
        async getDetailHandler(id,index){

            for(let i=0;i<detailCache.length;i++){
                if(detailCache[i]._id == id){
                    this.detail = detailCache[i];
                    this.dialogDyVisible = true;
                    return;
                }
            }

            let data = this.list[index];
            const loading = this.$loading();

            const imgList = [];
            data.mediaUrl.forEach(item=>{
                imgList.push({fileid:item})
            });
            const fileList = await this.$https.getImagePath(imgList);
            imgList.forEach((item,i)=>{
                imgList[i] = fileList[i].download_url;
            });
            data.mediaUrl = imgList;

            this.detail = data;
            detailCache.push(data);
            loading.close();
            this.dialogDyVisible=true;
            return;

            
            
            // const rs = await this.$https.cloud({
            //     action:'activity',
            //     type:'detail',
            //     formData:{
            //         _id: id
            //     }
            // });
            // this.$message(rs.msg);
            
            // if(rs.code!=0) return;
            // this.detail = rs.data.data[0];
            // this.dialogDyVisible = true;
            // const imgList = [];
            // rs.data.data[0].mediaUrl.forEach(item=>{
            //     imgList.push({fileid:item})
            // });
            // const fileList = await this.$https.getImagePath(imgList);
            // imgList.forEach((item,i)=>{
            //     imgList[i] = fileList[i].download_url;
            // });
            // rs.data.data[0].mediaUrl = imgList;
            // // console.log(rs.data.data[0])
            // this.detail = rs.data.data[0];
            
            // detailCache.push(rs.data.data[0])
        },

        //  分页 ========
        handleSizeChange(e){
            this.rows = e;
            this.getListHandler();
        },
        handleCurrentChange(e){
            this.currentPage = e;
            this.getListHandler();
        },
        editHandler(id){
            this.$router.push({
                name:'editDynamics',
                query:{
                    id: id
                }
            })
        },
        delHandler(id){
            //  删除
            this.$confirm('确定要删除动态吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(async ()=>{
                const rs = await this.$https.cloud({
                    action:'activity',
                    type:'del',
                    formData:{
                        _id: id
                    }
                });
                this.$message(rs.msg);
                if(rs.code!=0) return;
                this.list.pop();
                if(this.list.length==0&&this.currentPage>1){
                    this.currentPage -=1;
                    console.log(this.currentPage);
                }
                 this.getListHandler();
            }).catch()
        },
        selectionChangeHandler(data){
            console.log(data);
            delIds = [];
            data.forEach(item=>{
                delIds.push(item._id);
            })
        },
        async delsHandler(){
            if(delIds.length==0){
                this.$message.error('请选择数据');
                return;
            }
            this.$confirm('确定要删除动态吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(async ()=>{
                const rs = await this.$https.cloud({
                    action:'activity',
                    type:'dels',
                    formData:{
                        ids: delIds
                    }
                });
                if(rs.code!=0) {
                    this.$message.error(rs.msg);
                    return;
                }
                this.$message.success(rs.msg);
                if(this.list.length - delIds.length==0&&this.currentPage>1){
                    this.currentPage -=1;
                    console.log(this.currentPage);
                }
                 this.getListHandler();
            }).catch()

        },
        searchHandler(){
            this.currentPage = 1;
            this.getListHandler();
        },
        resetHandler(){
            this.currentPage = 1;
            this.searchMode = {
                time:[],
                search:''
            };
            this.getListHandler();
        },
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/common.scss';


.dynamic-manager {
    padding:  0 10px;
    background: #fff;

    ::v-deep.el-dialog__header {
    border-bottom: 1px solid #ccc;
}

    .search-wrapper {
        padding-top: 20px;
        border-bottom: 2px solid #ccc;
        font-size: 14px;
        .search-input {
            width: 225px;
            display: inline-block;
            margin-right: 25px;
        }
    }

     .dynamic-header {
        line-height: 68px;
        align-items: center;
        background: #fff;
        
        .title {
            font-size: 16px;
        }

        .add-good {
            display: inline-block;
            vertical-align: middle;
            margin-left: 10px;
            width:111px;
            height:30px;
            line-height: 32px;
            border: 1px solid $baseColor;
            border-radius:4px;
            font-size: 14px;
            color: $baseColor;
            text-align: center;  
        }
    }

    .table-wrapper {
        border-top: 1px solid #ccc;

        .table-img {
            width: 45px;
            margin-right: 0.8rem;
        }

        .table-name {
            font-size: 14px;
            line-height: 22px;
            flex: 1;
        }

        .table-btn {
            color: $baseColor;
            padding: 0 6px;
        }

        .table-btn +.table-btn {
            border-left: 1px solid #ccc;
        }

        .status-up {
            color: #52C31C;
        }

        .status-down {
            color: #FF7843;
        }
    }

    .pagination {
        padding:  10px;
        text-align: right;
    }

    .dialog-dynamic {
        font-size: 12px;
        color: #333;
        line-height: 2;

        .img {
            vertical-align: middle;
            margin: 10px;
            width: 80px;
            height: 80px;
        }

    }
}
</style>